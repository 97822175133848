import React from 'react';
import PortalDocuments from '../../Views/Portal/Documents/Documents';
import PortalAccount from '../../Views/Portal/Account/Account';
import PortalNav from '../../components/PortalNav/PortalNav';
import PortalAccountSecurity from '../../Views/Portal/Account/Security/Security';
import PortalAccountPayments from '../../Views/Portal/Account/Payments/Payments';
import PortalPackage from '../../Views/Portal/Package/Package';
import PortalAdvice from '../../Views/Portal/AdviceLine/AdviceLine';
import PortalTools from '../../Views/Portal/Tools/Tools';
import Calculators from '../../Views/Portal/Tools/Calculators/Calculators';
import MaternityPayCalculator from '../../Views/Portal/Tools/Calculators/Maternity-Pay-Calculator/Maternity-Pay-Calculator';
import AnnualLeaveCalculator from '../../Views/Portal/Tools/Calculators/Annuel-Leave-Calculator/Annuel-Leave-Calculator';
import HourlyLeaveCalculator from '../../Views/Portal/Tools/Calculators/Hourly-Leave-Calculator/Hourly-Leave-Calculator';
import RedundancyPayCalculator from '../../Views/Portal/Tools/Calculators/Redundancy-Pay-Calculator/Redundancy-Pay-Calculator';
import PortalReports from '../../Views/Portal/AdviceLine/Reports/Reports';
import PortalEmployee from '../../Views/Portal/Employees/Employee/Employee';
import PortalDepartments from '../../Views/Portal/Employees/Departments/Departments';
import PortalTeams from '../../Views/Portal/Employees/Teams/Teams';
import PortalEmployeeDocuments from '../../Views/Portal/Employees/Documents/Documents';
import { Router } from "@reach/router";
import PortalHierarchy from '../../Views/Portal/Employees/Hierarchy/Hierarchy';
import PortalReferences from '../../Views/Portal/Employees/References/References';
import PortalDashboard from '../../Views/Portal/Dashboard/Dashboard';
import PortalPolicyLibrary from '../../Views/Portal/Employees/Policy-Library/Policy-Library';
import PortalSmartHub from '../../Views/Portal/SmartHub/SmartHub';
import Workflows from '../../Views/Portal/Tools/Workflows/Workflows';
import RecruitmentWorkflow from '../../Views/Portal/Tools/Workflows/RecruitmentWorkflow/Recruitment/Recruitment';
import RecruitmentWorkflowList from '../../Views/Portal/Tools/Workflows/RecruitmentWorkflow/RecruitmentList';
import PortalCompanyCalendar from '../../Views/Portal/CompanyCalendar/CompanyCalendar';
import PortalDepartment from '../../Views/Portal/Employees/Departments/Department/Department';
import PortalTeam from '../../Views/Portal/Employees/Teams/Team/Team';
import EmployeeHolidayRequests from '../../Views/EmployeePortal/Holiday-Requests/Holiday-Requests';
import OnboardingWorkflowList from '../../Views/Portal/Tools/Workflows/OnboardingWorkflow/OnboardingList';
import OnboardingWorkflow from '../../Views/Portal/Tools/Workflows/OnboardingWorkflow/Onboarding/Onboarding';
import { Package } from '../../Classes/Package';
import PortalAccountBusiness from '../../Views/Portal/Account/Business/Business';
import PortalAccountProgress from '../../Views/Portal/Account/Progress/Progress';
import BenefitsPerksList from '../../Views/Portal/Benefits/Benefits';
import AssetManagement from '../../Views/Portal/Assets/Assets';
import AssetForm from '../../Views/Portal/Assets/Asset/Asset';
import OffboardingWorkflowList from '../../Views/Portal/Tools/Workflows/OffboardingWorkflow/OffboardingList';
import OffboardingWorkflow from '../../Views/Portal/Tools/Workflows/OffboardingWorkflow/Offboarding/Offboarding';
import PortalLayout from '../../components/Layout/PortalLayout';
import PortalMoodMonitor from '../../Views/Portal/MoodMonitor/MoodMonitor';
import PortalTaskManager from '../../Views/Portal/Employees/TaskManager/TaskManager';
import Courses from '../../Views/Portal/Courses/Courses/Table/Courses';
import Modules from '../../Views/Portal/Courses/Modules/Table/Modules';
import Lesson from '../../Views/Portal/Courses/Lessons/Lesson/Lesson';
import Lessons from '../../Views/Portal/Courses/Lessons/Table/Lessons';
import PortalRota from '../../Views/Portal/ShiftScheduling/ShiftScheduling';
import PortalEmployees from '../../Views/Portal/Employees/Employees';
import PortalAccountSettings from '../../Views/Portal/Account/Settings/Settings';
import ReleaseNotes from '../../Views/Portal/ReleaseNotes/ReleaseNotes';

const PortalPage: React.FC = (props: any) => {
  const Packages: Package[] = props.pageContext.Packages;

  return (<PortalLayout>
    <PortalNav PackageData={Packages}>
      <Router basepath="/Portal" className={"router"}>
        <PrivateRoute path="/" component={PortalDashboard} />
        <PrivateRoute path="/Documents" component={PortalDocuments} />
        <PrivateRoute path="/ReleaseNotes" component={ReleaseNotes} />

        <PrivateRoute path="/Account/Security" component={PortalAccountSecurity} />
        <PrivateRoute path="/Account/Payments" component={PortalAccountPayments} />
        <PrivateRoute path="/Account/Business" component={PortalAccountBusiness} />
        <PrivateRoute path="/Account/Progress" component={PortalAccountProgress} />
        <PrivateRoute path="/Account/Settings" component={PortalAccountSettings} />
        <PrivateRoute path="/Account" component={PortalAccount} />

        <PrivateRoute path="/Package" component={PortalPackage} />
        <PrivateRoute path="/Company%20Calendar" component={PortalCompanyCalendar} />

        <PrivateRoute path="/AdviceLine/Reports" component={PortalReports} />
        <PrivateRoute path="/AdviceLine" component={PortalAdvice} />

        <PrivateRoute path="/Benefits" component={BenefitsPerksList} />

        <PrivateRoute path="/Asset%20Management" component={AssetManagement} />
        <PrivateRoute path="/Asset%20Management/Asset/:ID" component={AssetForm} />

        <PrivateRoute path="/Storage" component={PortalSmartHub} />
        <PrivateRoute path="/Storage/:ID" component={PortalSmartHub} />

        <PrivateRoute path="/Employees/Employee/:ID/:Tab/:RefID" component={PortalEmployee} />
        <PrivateRoute path="/Employees/Employee/:ID/:Tab" component={PortalEmployee} />
        <PrivateRoute path="/Employees/Employee/:ID" component={PortalEmployee} />
        <PrivateRoute path="/Employees/Documents/:ID" component={PortalEmployeeDocuments} />
        <PrivateRoute path="/Employees/Heirarchy" component={PortalHierarchy} />
        <PrivateRoute path="/Employees/Policy%20Library" component={PortalPolicyLibrary} />
        <PrivateRoute path="/Employees/Mood%20Monitor" component={PortalMoodMonitor} />
        <PrivateRoute path="/Employees/Task%20Manager" component={PortalTaskManager} />
        <PrivateRoute path="/Employees/Shift%20Scheduling" component={PortalRota} />
        <PrivateRoute path="/Employees" component={PortalEmployees} />

        <PrivateRoute path="/Employees/Departments" component={PortalDepartments} />
        <PrivateRoute path="/Employees/Departments/Department/:ID" component={PortalDepartment} />
        <PrivateRoute path="/Employees/Teams" component={PortalTeams} />
        <PrivateRoute path="/Employees/Teams/:ID" component={PortalTeams} />
        <PrivateRoute path="/Employees/Teams/Team/:ID" component={PortalTeam} />
        <PrivateRoute path="/Employees/References" component={PortalReferences} />
        <PrivateRoute path="/Employees/Leave%20Requests/:RefID" component={EmployeeHolidayRequests} />
        <PrivateRoute path="/Employees/Leave%20Requests" component={EmployeeHolidayRequests} />

        <PrivateRoute path="/Tools" component={PortalTools} />
        <PrivateRoute path="/Tools/Calculators" component={() => <Calculators hideBanner={true} />} />
        <PrivateRoute path="/Tools/Calculators/Maternity%20Pay%20Calculator" component={() => <MaternityPayCalculator hideBanner={true} />} />
        <PrivateRoute path="/Tools/Calculators/Hourly%20Leave%20Calculator" component={() => <HourlyLeaveCalculator hideBanner={true} />} />
        <PrivateRoute path="/Tools/Calculators/Annual%20Leave%20Calculator" component={() => <AnnualLeaveCalculator hideBanner={true} />} />
        <PrivateRoute path="/Tools/Calculators/Redundancy%20Pay%20Calculator" component={() => <RedundancyPayCalculator hideBanner={true} />} />

        <PrivateRoute path="/Tools/Workflows" component={Workflows} />
        <PrivateRoute path="/Tools/Workflows/Recruitment" component={RecruitmentWorkflowList} />
        <PrivateRoute path="/Tools/Workflows/Onboarding" component={OnboardingWorkflowList} />
        <PrivateRoute path="/Tools/Workflows/Onboarding/Item/:ID" component={OnboardingWorkflow} />
        <PrivateRoute path="/Tools/Workflows/Offboarding" component={OffboardingWorkflowList} />
        <PrivateRoute path="/Tools/Workflows/Offboarding/Item/:ID" component={OffboardingWorkflow} />
        <PrivateRoute path="/Tools/Workflows/Recruitment/Item/:ID" component={RecruitmentWorkflow} />

        <PrivateRoute path={"/Courses"} component={Courses} />
        <PrivateRoute path={"/Courses/Modules/:CourseID"} component={Modules} />
        <PrivateRoute path={"/Courses/Modules/Lessons/:ModuleID"} component={Lessons} />
        <PrivateRoute path={"/Courses/Modules/Lesson/:LessonID/Slide/:SlideID"} component={Lesson} />
      </Router>
    </PortalNav>
  </PortalLayout>);
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Component {...rest} />
}

export default PortalPage;
